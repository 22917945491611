import React from 'react';
import {useSelector} from 'react-redux';

function TrainingTemplatesScreen() {
  const items = useSelector(store => store.data.items && store.data.items.trainingTemplates);
  console.log(items);
  
  return (
    <div>
        
    </div>
  );
}

export default TrainingTemplatesScreen;
