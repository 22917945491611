import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import SelectDialog from '../../../components/SelectDialog';

export default function TemplateDialog(props) {
    const items = useSelector(store => store.data.items.trainingTemplates || {});

    const handleClose = (value) => {
        if (!value) return props.onClose(value);
        const item = value && items[value];
        props.onClose(item.item);
    }

    return (
        <SelectDialog open={props.open} onClose={handleClose} title="Select template" items={Object.keys(items)} />
    );
}

TemplateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
  };