import React from 'react';
import {useSelector} from 'react-redux';

export function createORMRowContainer(Component, tableKey) {
  if (!tableKey) throw new Error('createORMRowContainer(Component, tableKey): tableKey must be defined');
  return (props) => {
    const table = useSelector(state => state.data.tables && state.data.tables[tableKey]);
    const dates = useSelector(state => state.data.dates && state.data.dates[tableKey]);

    return (table && dates) ? <Component {...props} key={props.date} item={dates[props.date] && dates[props.date].item} /> : null;
  };
}
