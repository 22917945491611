import React, {useMemo} from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import reduce from "lodash/fp/reduce";
import sortBy from "lodash/fp/sortBy";
import uniq from "lodash/fp/uniq";
import flow from "lodash/fp/flow";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { property } from 'lodash';

// import {parseExerciseValue} from '../../../helpers';

const useStyles = makeStyles(theme => ({
  exercise: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > *': {
      width: '48%',
      marginBottom: '5px'
    }
  }
}));

export default function Exercise(props) {
  const classes = useStyles();
  const previous = useSelector(store => {
    return flow(
      sortBy(store.data.dates.training, data => data.item.date, 'desc'),
      reduce((memo, {item}) => {
        if (memo) return memo;
        if (!item) return memo;

        if (props.date > item.date) {
          memo = reduce((memo, search) => {
            if (search.name === props.exercise.name) {
              memo = search;
            }
            return memo;
          }, null)(item.exercises)
        }
        return memo;
      }, null)
    )(store.data.dates.training);
  });

  const exercises = useSelector(store => {
    const exercises = flow(
      reduce((memo, {item}) => {
        return memo.concat(item && item.exercises ? item.exercises.map(property('name')) : []);
      }, []),
      uniq
    )(store.data.dates.training);

    return exercises;
  });

  const handleChange = (key) => (event) => {
    props.onChange({
      ...props.exercise,
      [key]: event.target ? event.target.value : event
    });
  };

  // const e1rm = useMemo(() => {
  //   const sets = parseExerciseValue(props.exercise.value);
  //   if (!sets.length) return null;

  //   return sets.reduce((e1rm, set) => {
  //     if (!e1rm || set.e1rm > e1rm) {
  //       e1rm = set.e1rm;
  //     }
  //     return e1rm;
  //   }, null);
  // }, [props.exercise.value]);

  const valueHelperText = useMemo(() => {
    let help = [];
    if (previous) {
      help.push(`Previously ${previous.value}`);
    }
    // if (e1rm) {
    //   help.push(`${e1rm}e1RM`);
    // }
    return help.join(' • ');
  }, [previous]);

  return (
    <div className={classes.exercise}>
      <Autocomplete
        freeSolo
        options={exercises.filter(exercise => props.exercise.name && exercise.toLowerCase().includes(props.exercise.name.toLowerCase()))}
        value={props.exercise.name || ""}
        onInputChange={(event, value) => handleChange('name')(value)}
        renderInput={(params) => (
          <TextField
            size="small"
            label={"Exercise"}
            disabled={false}
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
      <TextField
        size="small"
        label={" "}
        disabled={false}
        value={props.exercise.value || ""}
        onChange={handleChange("value")}
        helperText={valueHelperText}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          style: {
            padding: '6px 0'
          }
        }}
      />
    </div>
  )
}