import {times} from 'lodash';

export function formatNumber(number : number, decimals = 1) {
    if (number === undefined || number === null) return 'N/A';
    const positive = parseFloat(number.toFixed(decimals)) > 0;
    return (positive ? '+' : '') + number.toFixed(decimals);
}

type Unit = 'kgs' | 'lbs'

export interface Set {
    reps : number
    weight : number
    unit : Unit
    e1rm : number
}

function e1rm(reps : number, weight : number) {
    if (reps === 1) return weight;
    return Math.floor(weight*(1+(0.0333*reps)));
}

type StringTuple = [string, string];
function parseSections(input : string) : StringTuple[] {
    let items = input.split('@');

    let left = items.shift();
    if (!left) return [];

    let right = items.shift();
    if (!right) return [];
    if (items.length) {
        let rightSplit = right.split(" ");
        right = rightSplit[0];
        items.unshift(rightSplit[1]);
    }

    let current : StringTuple = [left, right];
    let next = parseSections(items.join('@'))
    return [current].concat(next);
}

export function parseExerciseValue(input : string) : Set[] {
    if (!input) return [];
    if (!input.length) return [];
    if (input.includes('in')) return [];
    if (input.includes('+')) return [];
    if (input.includes('?')) return [];
    if (input.includes('reps at')) return [];

    let sections = parseSections(input);
    let sets : Set[] = [];

    sections.forEach(([left, right]) => {
        let unit : Unit = left.includes('lbs') ? 'lbs' : 'kgs';
        let weight = parseFloat(right);

        left.split(',').forEach(section => {
            if (section.toLowerCase().includes('x')) {
                let [left, right] = section.split('x');
                let numSets = parseInt(left.trim(), 10);
                let reps = parseInt(right.trim(), 10);

                times(numSets).forEach(() => {
                    sets.push({
                        reps,
                        weight,
                        unit,
                        e1rm: e1rm(reps, weight)
                    });
                });
            } else {
                let reps = parseInt(section.trim(), 10);
                sets.push({
                    reps,
                    weight,
                    unit,
                    e1rm: e1rm(reps, weight)
                });
            }
        });
    });

    return sets;
}