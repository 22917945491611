import React, {useMemo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import last from 'lodash/last';
import first from 'lodash/first';
import moment from 'moment';
import DayForm from './components/DayForm';
import WeekHistory, {useWeeks} from './components/WeekHistory';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#FFF',
    textDecoration: 'none',
  },
  content: {
    padding: '25px',
    width: 'auto',
    maxWidth: '600px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  },
  centered: {
    display: 'flex',
    justifyContent: 'center'
  },
  contentSpacer: {
    marginBottom: '15px'
  },
  summary: {
    fontSize: '0.9em',
    lineHeight: '1.4em'
  }
}));

function TDEEScreen() {
  const classes = useStyles();

  const today = moment().format('YYYY-MM-DD');
  const numWeeks = useMemo(() => moment().diff(moment('2021-04-12'), 'weeks'), []);
  const weeks = useWeeks(today, numWeeks, 1);

  return (
    <div style={{width: '100%'}}>
      <DayForm date={today} />
      <div className={classes.contentSpacer} />
      <WeekHistory date={today} weeks={numWeeks} />
      <div className={classes.contentSpacer} />
      {(weeks && weeks.length >= 2) ? (
        <>
          <div style={{textAlign: 'center', marginBottom: '10px', fontWeight: 'bold'}}>Progress</div>
          <div className={classes.summary}>
            You have lost <strong>{(last(weeks).averageWeight - first(weeks).averageWeight).toFixed(1)}kg</strong> in {weeks.length - 1} weeks.<br />
            This is <strong>{((last(weeks).averageWeight - first(weeks).averageWeight) / (weeks.length - 1)).toFixed(1)}kg per week</strong> on average.<br />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default TDEEScreen;
