import React, {useState, useReducer} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {createORMColumnContainer} from '../../../components/ORMColumn';
import Button from '../../../components/Button';
import Exercise from './Exercise';
import {data as dataActions} from '../../../actions';
import TemplateDialog from './TemplateDialog';

const useStyles = makeStyles(theme => ({
  exercise: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginBottom: '5px',

    '& > *:first-child': {
      marginRight: '10px'
    },
    '& > *:nth-child(2)': {
      flexGrow: 1
    }
  },
  deleteButton: {
    cursor: 'pointer',
    padding: '7px'
  }
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function Day(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector((store) => {
    return get(store.data.dates, `training.${props.date}.saving`, false)
  });
  const [isTemplateDialogOpen, toggleTemplateDialog] = useReducer((value) => !value, false);

  const [exercises, setExercises] = useState(props.item ? (props.item.exercises || []) : []);
  const handleAddExercise = () => {
    setExercises(exercises => exercises.concat([{}]))
  };

  const handleDeleteExercise = (exercise) => () => {
    if (window.confirm('Are you sure?')) {
      setExercises(exercises => exercises.filter(search => search !== exercise));
    }
  };

  const handleSave = () => {
    dispatch(dataActions.upsertTrainingDate({
      date: props.date,
      exercises,
    }));
  };

  const handleChange = (search) => (update) => {
    setExercises(exercises => exercises.map((exercise) => {
      if (exercise === search) {
        return {
          ...exercise,
          ...update
        };
      }
      return exercise;
    }));
  }

  const handleTemplate = (template) => {
    toggleTemplateDialog();

    if (template) {
      setExercises(exercises => exercises.concat(template.exercises))
    }
  }

  const handleDragEnd = (result) => {
    setExercises(exercises => reorder(exercises, result.source.index, result.destination.index));
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="exercises">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {exercises.map((exercise, index) => (
                <Draggable key={index} draggableId={index.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={classes.exercise}
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div {...provided.dragHandleProps}>
                        <DragIndicatorIcon/>
                      </div>
                      <Exercise date={props.date} exercise={exercise} onChange={handleChange(exercise)} />
                      <div className={classes.deleteButton} onClick={handleDeleteExercise(exercise)}>
                        <DeleteOutlinedIcon/>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button onClick={handleSave} working={isSaving} style={{float: 'right'}}>Save</Button>
      <div style={{float: 'left'}}>
        <Button onClick={handleAddExercise}>Add exercise</Button>
        <Button onClick={toggleTemplateDialog}>Load template</Button>
      </div>

      <TemplateDialog onClose={handleTemplate} open={isTemplateDialogOpen} />
    </div>
  )
}

Day.propTypes = {
  date: PropTypes.string.isRequired
};

export default createORMColumnContainer(Day, 'training');