import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {selectDate} from '../../../actions';

const useStyles = makeStyles(theme => ({
  navigation: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .buttons': {
      '& *': {
        cursor: 'pointer'
      },
      '& .disabled': {
        color: 'grey'
      }
    }
  },
  wrapper: {
    margin: '10px',
    padding: '0px 16px',
  },
  today: {
    margin: '10px',
    textAlign: 'center'
  }
}));

export function DateNavigation(props) {
  const classes = useStyles();
  const today = moment();
  const isToday = today.isSame(props.date, 'day');

  return (
    <div className={classes.navigation}>
      <div className="buttons"><NavigationButton on="day" direction="previous" date={props.date} onChange={props.onChange} /></div>
      <div>
        <strong>{moment(props.date).format('dddd YYYY-MM-DD')}</strong>
        {!isToday && (<div className={classes.today} onClick={() => props.onChange(today.toDate())}>Today</div>)}
      </div>
      <div className="buttons"><NavigationButton on="day" direction="next" date={props.date} onChange={props.onChange} /></div>
    </div>
  );
}

export default function DateNavigationContainer() {
  const date = useSelector(store => store.selection.training.date);
  const dispatch = useDispatch();
  const handleChange = (date) => {
    dispatch(selectDate({
      table: 'training',
      date
    }));
  }
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <DateNavigation date={date} onChange={handleChange} />
    </div>
  )
}

function NavigationButton(props) {
  const handleClick = () => {
    props.onChange(
      props.direction === 'previous' ? moment(props.date).subtract(1, 'day').toDate() : moment(props.date).add(1, 'day').toDate()
    );
  };

  return (
    <span onClick={handleClick}>
      {props.direction === 'previous' ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
    </span>
  );
}
