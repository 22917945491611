import React from 'react';
import { useSelector} from 'react-redux';
import moment from 'moment';

import DateNavigation from './components/DateNavigation';
import Day from './components/Day';

function TrainingLogScreen() {
  const date = useSelector(store => store.selection.training.date);
  return (
    <div style={{width: '100%'}}>
      <DateNavigation />
      <Day date={moment(date).format('YYYY-MM-DD')} />
    </div>
  );
}

export default TrainingLogScreen;
