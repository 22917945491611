import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import {createORMRowContainer} from './../../../components/ORMRow';
import Button from './../../../components/Button';
import {useWeeks} from './WeekHistory';
import {formatNumber} from './../../../helpers';
import {data as dataActions} from './../../../actions';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    '& > *': {
      marginBottom: '10px'
    }
  },
  date: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  inputs: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  input: {
    flexBasis: '45%'
  }
}));

export function DayForm(props) {
  const [weight, setWeight] = useState(props.item ? props.item.weight : null);
  const [calories, setCalories] = useState(props.item ? props.item.calories : null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector((store) => get(store.data.dates, `tdee.${props.date}.saving`, false));
  // const isLoading = useSelector((store) => get(store.data.dates, `${props.date}.loading`, true));

  const handleSave = () => {
    dispatch(dataActions.upsertTdeeDate({
      date: props.date,
      weight: parseFloat(weight, 10) || null,
      calories: parseFloat(calories, 10) || null
    }));
  };

  const week = useWeeks(props.date, 1, 0);

  return (
    <div className={classes.wrapper}>
      <div className={classes.date}>{props.date}</div>

      <div className={classes.inputs}>
        <TextField
          className={classes.input}
          label="Weight"
          value={weight || ''}
          onChange={(event) => setWeight(event.target.value)}
          margin="none"
          variant="filled"
          type="number"

          InputProps={{
            startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
          }}
        />

        <TextField
          className={classes.input}
          label="Calories"
          value={calories || ''}
          onChange={(event) => setCalories(event.target.value)}
          margin="none"
          variant="filled"
          type="number"
        />
      </div>

      <Button variant="contained" onClick={handleSave} working={isSaving}>Save</Button>

      {week && (
        <>
          <div className={classes.date}>Week</div>
          <div className={classes.inputs}>
            <TextField
              label="Weight"
              className={classes.input}
              defaultValue={week[0].averageWeight ? week[0].averageWeight.toFixed(1) : 'N/A'}
              InputProps={{
                readOnly: true,
                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
              }}
            />

            <TextField
              label="Change"
              className={classes.input}
              defaultValue={week[0].weightChange !== undefined ? formatNumber(week[0].weightChange) : 'N/A'}
              InputProps={{
                readOnly: true,
                startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
              }}
            />

            <TextField    
              label=" "
              className={classes.input}
              defaultValue={week[0].weightChange !== undefined ? `${formatNumber(week[0].weightChangePercentage)}%` : 'N/A'}
              InputProps={{
                readOnly: true,
                startAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
            </div>
        </>
      )}

    </div>
  )
}

DayForm.propTypes = {
  date: PropTypes.string.isRequired
};

export default createORMRowContainer(DayForm, 'tdee');
