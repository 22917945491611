import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import range from 'lodash/range';
import mean from 'lodash/mean';
import values from 'lodash/values';
import property from 'lodash/property';
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';

import {dateFromToPredicate} from '../../../actions';
import {formatNumber} from '../../../helpers';

const useStyles = makeStyles(theme => ({
  weeks: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    margin: '0px -5px',
    overflowX: 'auto'
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '10px'
  },
  week: {
    margin: '0px 5px',
    minWidth: '100px',
    '& .legend': {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.8)',
      marginBottom: '10px '
    },
    '& .content': {
      padding: '10px 10px 0px 10px'
    },
    '& .values > *': {
      marginBottom: '10px'
    }
  }
}));

function weekData(dates, {from, to}) {
  const weekDates = dates.filter(dateFromToPredicate(from, to)).filter(property('item'))

  return {
    from,
    to,
    dates: weekDates,
    averageWeight: weekDates.length >= 2 ? mean(weekDates.map(day => day.item.weight)) : null,
    averageCalories: weekDates.map(day => day.item.calories).length >= 2 ? mean(weekDates.map(day => day.item.calories)) : null
  };
}

export function useWeeks(date, length = null, offset = 0) {
  const dates = useSelector((store) => store.data.dates && values(store.data.dates.tdee));

  if (!dates) return null;
  if (dates.length < 8) return null;
  if (length === null) {
    const minDate = minBy(dates, property('item.date')).item.date;
    const maxDate = maxBy(dates, property('item.date')).item.date;
    const diff = Math.abs(moment(minDate).diff(maxDate, 'weeks')) + 1;

    length = diff - offset;
  }

  const weeks = range(1, length + offset + 1).reduce((memo, number) => {
    return memo.concat({
      to: memo[0].to.clone().subtract(number * 7, 'days'),
      from: memo[0].from.clone().subtract(number * 7, 'days')
    });
  }, [
    {
      to: moment(date).endOf('isoWeek'),
      from: moment(date).startOf('isoWeek').startOf('day')
    }
  ]).map(week => weekData(dates, week)).map((week, index, source) => {
    const previous = source[index + 1];
    if (previous && previous.averageWeight && week.averageWeight) {
      week.weightChange = week.averageWeight - previous.averageWeight;
      week.weightChangePercentage = week.averageWeight / previous.averageWeight * 100 - 100;
    }
    return week;
  });

  return weeks.slice(0 + offset, offset + length);
};

export function WeekHistory(props) {
  const classes = useStyles();

  const weeks = useWeeks(props.date, props.weeks, 1);
  const dates = useSelector((store) => store.data.dates && values(store.data.dates.tdee));

  if (!dates) return null;
  if (dates.length < 8) return null;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>Previous weeks</div>
      <div className={classes.weeks}>
        {weeks.map(week => <WeekDisplay key={week.from.format('YYYY-MM-DD')} week={week} />)}
      </div>
    </div>
  );
}

WeekHistory.propTypes = {
  date: PropTypes.string.isRequired,
  weeks: PropTypes.number.isRequired
};

export default WeekHistory;

export function WeekDisplay(props) {
  const classes = useStyles();

  return (
    <Card key={props.week.from.format('YYYY-MM-DD')} className={classes.week} variant="outlined">
      <CardContent className="content">
        <Typography className="legend">
          {props.week.from.format('MMM Do')}
        </Typography>

        <div className="values">
          <TextField
            label="Weight"
            defaultValue={props.week.averageWeight ? props.week.averageWeight.toFixed(1) : 'N/A'}
            InputProps={{
              readOnly: true,
              startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
            }}
          />

          <TextField
            label="Change"
            defaultValue={props.week.weightChange !== undefined ? formatNumber(props.week.weightChange) : ''}
            InputProps={{
              readOnly: true,
              startAdornment: <InputAdornment position="start">Kg</InputAdornment>
            }}
          />

          <TextField
            defaultValue={props.week.weightChange !== undefined ? `${formatNumber(props.week.weightChangePercentage)}%` : ''}
            InputProps={{
              readOnly: true,
              startAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />
        </div>
      </CardContent>
    </Card>
  );
}
