import GoogleSheetsORM from 'google-sheets-orm';
import map from 'lodash/map';
import moment from 'moment';

export function authSetLoggedin(value) {
  return {
    type: 'AUTH_SET_LOGGED_IN',
    value
  };
}

export function dateFromToPredicate(from, to) {
  return function (input) {
    if (input.item) input = input.item;
    const date = moment(input.date);
    return date.isSameOrBefore(to) && date.isSameOrAfter(from);
  };
}

export const data = {
  init: function (orm) {
    const db = orm.db('RZBB');
    const trainingLogTable = db.table('Training', {
      date: {
        type: String,
        primaryKey: true,
        required: true
      },
      exercises: {
        type: Array({
          name: {
            type: String
          },
          value: {
            type: String
          }
        })
      }
    }, {
      mode: GoogleSheetsORM.COLUMN,
      insertOrder: GoogleSheetsORM.PREPEND
    });

    const trainingTemplatesTable = db.table('Training - Templates', {
      name: {
        type: String,
        primaryKey: true,
        required: true
      },
      exercises: {
        type: Array({
          name: {
            type: String
          },
          value: {
            type: String
          }
        })
      }
    }, {
      mode: GoogleSheetsORM.COLUMN,
      insertOrder: GoogleSheetsORM.PREPEND
    });

    const tdeeTable = db.table('TDEE', {
      date: {
        type: String,
        primaryKey: true,
        required: true
      },
      weight: {
        type: Number
      },
      calories: {
        type: Number
      }
    });

    return (dispatch, getState) => {
      Promise.all([
        trainingLogTable.ddl(),
        trainingTemplatesTable.ddl(),
        tdeeTable.ddl()
      ]).then(() => {
        dispatch({
          type: 'DATA_INIT',
          orm,
          db,
          tables: {
            training: trainingLogTable,
            trainingTemplates: trainingTemplatesTable,
            tdee: tdeeTable
          }
        });

        return this.loadAll()(dispatch, getState);
      }).catch(console.error.bind(console));
    };
  },
  loadAll: function () {
    return (dispatch, getState) => {
      return Promise.all(
        map(
          getState().data.tables,
          (table, key) => {
            return table.findAll().then((items) => {
              if (table.pk === 'date') {
                dispatch({
                  table: key,
                  type: 'DATA_DATES_LOAD_DONE',
                  items
                });
              } else {
                dispatch({
                  table: key,
                  type: 'DATA_LOAD_DONE',
                  items
                });
              }
            });
          }
        )
      );
    };
  },
  upsertTrainingDate: function (values) {
    return (dispatch, getState) => {
      dispatch({
        table: 'training',
        type: 'DATA_DATE_UPSERT',
        values
      });
      return getState().data.tables.training.upsert(values).then((item) => {
        dispatch({
          table: 'training',
          type: 'DATA_DATE_UPSERT_DONE',
          values,
          item
        });
      });
    }
  },
  upsertTdeeDate: function (values) {
    return (dispatch, getState) => {
      dispatch({
        table: 'tdee',
        type: 'DATA_DATE_UPSERT',
        values
      });
      return getState().data.tables.tdee.upsert(values).then((item) => {
        dispatch({
          table: 'tdee',
          type: 'DATA_DATE_UPSERT_DONE',
          values,
          item
        });
      });
    }
  }
};

export function selectDate(value) {
  localStorage.setItem(`selection.${value.table}.date`, value.date.toJSON());

  return {
    type: 'SELECT_DATE',
    table: value.table,
    date: value.date
  };
}