import React from 'react';
import {omit} from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function Button(props) {
  const classes = useStyles();
  return (
    <div className={`${classes.wrapper} ${props.className ? props.className : ''}`} style={props.style}>
      <MaterialButton {...omit(props, 'working', 'className', 'style')} disabled={props.working}>
        {props.children}
      </MaterialButton>
      {props.working && (<CircularProgress className={classes.loading} size={24} />)}
    </div>
  );
}
