const initialState = {
  auth: {
    loggedIn: false
  },
  data: {
    orm: null,
    db: null,
    tables: null,
    dates: {},
    items: {}
  },
  selection: {
    training: {
      date: localStorage.getItem('selection.training.date') ? new Date(localStorage.getItem('selection.training.date')) : new Date()
    },
    tdee: {
      date: localStorage.getItem('selection.tdee.date') ? new Date(localStorage.getItem('selection.tdee.date')) : new Date()
    }
  },
};

function DATA_REDUCER(state, action) {
  switch (action.type) {
    case 'DATA_INIT':
      return {
        orm: action.orm,
        db: action.db,
        tables: action.tables
      };
    case 'DATA_DATE_UPSERT':
      return {
        dates: {
          ...state.dates,
          [action.table]: {
            ...state.dates[action.table],
            [action.values.date]: {
              ...(state.dates[action.table][action.values.date] || {}),
              saving: true
            }
          }
        }
      };
    case 'DATA_DATE_UPSERT_DONE':
      return {
        dates: {
          ...state.dates,
          [action.table]: {
            ...state.dates[action.table],
            [action.values.date]: {
              ...(state.dates[action.table][action.values.date] || {}),
              saving: false,
              item: action.item
            }
          }
        }
      };
    case 'DATA_DATES_LOAD_DONE':
      return {
        dates: {
          ...state.dates,
          [action.table]: {
            ...action.items.reduce((memo, item) => {
              memo[item.date] = {
                ...(state.dates && state.dates[action.table] ? state.dates[action.table][item.date] : {}),
                loading: false,
                item: item
              };
              return memo;
            }, {})
          }
        }
      };
      case 'DATA_LOAD_DONE':
        return {
          items: {
            ...state.items,
            [action.table]: {
              ...action.items.reduce((memo, item) => {
                const pk = item[state.tables[action.table].pk];

                memo[pk] = {
                  ...(state.items && state.items[action.table] ? state.items[action.table][pk] : {}),
                  loading: false,
                  item: item
                };
                return memo;
              }, {})
            }
          }
        };
    default:
      return state;
  }
}

function SELECTION_REDUCER(state, action) {
  switch (action.type) {
    case 'SELECT_DATE':
      return {
        ...state,
        [action.table]: {
          ...state[action.table],
          date: action.date
        }
      };
    default:
      return state;
  }
}

export default function reducer(state = initialState, action) {
  if (action.type.startsWith('DATA_')) {
    return {
      ...state,
      data: {
        ...state.data,
        ...DATA_REDUCER(state.data, action)
      }
    };
  }

  if (action.type.startsWith('SELECT_')) {
    return {
      ...state,
      selection: {
        ...state.selection,
        ...SELECTION_REDUCER(state.data, action)
      }
    };
  }

  switch (action.type) {
    case 'AUTH_SET_LOGGED_IN':
      return {
        ...state,
        auth: {
          ...state.auth,
          loggedIn: action.value
        }
      };

    default:
      return state;
  }
}
