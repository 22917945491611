import React, {useEffect, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import GoogleSheetsORM from 'google-sheets-orm';
import {
  Switch,
  Route,
  Link,
  useHistory,
  useLocation
} from 'react-router-dom';

import MenuIcon from '@material-ui/icons/Menu';
import {AppBar, Toolbar, Button, Typography, List, ListItem, ListItemText, IconButton, Hidden, Drawer} from '@material-ui/core';
import {authSetLoggedin, data as dataActions} from './actions';
import TrainingLogScreen from './screens/TrainingLog';
import TrainingTemplatesScreen from './screens/TrainingTemplates';
import TDEEScreen from './screens/TDEE';
import TrainingPRsScreen from './screens/TrainingPRs';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center'
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    }
  },
  toolBar: {
    justifyContent: 'space-between'
  },
  title: {
    color: '#FFF',
    textDecoration: 'none',
    marginRight: theme.spacing(2),
    display: 'inline-flex',
    alignItems: 'center'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    maxWidth: '600px',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },
  centered: {
    flexGrow: 1,
    justifyContent: 'center'
  },
  appBarSpacer: theme.mixins.toolbar,
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  drawerPaper: {
    width: drawerWidth,
  }
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(store => store.auth.loggedIn);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    window.gapi.load('client:auth2', function () {
      window.gapi.client.init({
        clientId: '807512990390-grpfsdec7plusfdalf1k0c16sckgg9l0.apps.googleusercontent.com',
        discoveryDocs: GoogleSheetsORM.DISCOVERY_DOCS,
        scope: GoogleSheetsORM.SCOPES
      }).then(function () {
        dispatch(authSetLoggedin(window.gapi.auth2.getAuthInstance().isSignedIn.get()));
        window.gapi.auth2.getAuthInstance().isSignedIn.listen((isSignedIn) => {
          dispatch(authSetLoggedin(isSignedIn));

          if (isSignedIn) {
            dispatch(dataActions.init(new GoogleSheetsORM(window.gapi.client)));
          }
        });

        if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
          dispatch(dataActions.init(new GoogleSheetsORM(window.gapi.client)));
        }
      });
    });
  }, [dispatch]);

  const handleLogin = () => {
    window.gapi.auth2.getAuthInstance().signIn();
  };

  const handleLogout = () => {
    window.gapi.auth2.getAuthInstance().signOut();
  };

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigate = (url) => () => {
    setMobileOpen(false);
    history.push(url);
  }

  const drawer = (
    <React.Fragment>
      <List>
        <ListItem button key={"TDEE"} onClick={navigate('/tdee')}>
          {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
          <ListItemText primary={"TDEE"} />
        </ListItem>
        <ListItem button key={"Training Log"} onClick={navigate('/training/log')}>
          {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
          <ListItemText primary={"Training Log"} />
        </ListItem>
        <ListItem button key={"Training Templates"} onClick={navigate('/training/templates')}>
          {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
          <ListItemText primary={"Training Templates"} />
        </ListItem>
        <ListItem button key={"Training PRs"} onClick={navigate('/training/prs')}>
          {/*<ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>*/}
          <ListItemText primary={"Training PRs"} />
        </ListItem>
      </List>
    </React.Fragment>
  );

  const container = window.document.body;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Link to={location.pathname} className={classes.title}>
              <Typography variant="h6" style={{display: 'inline-block'}} >
                <Switch>
                  <Route path="/tdee">
                    TDEE
                  </Route>
                  <Route path="/training/log">
                    Training Log
                  </Route>
                  <Route path="/training/templates">
                    Training Templates
                  </Route>
                  <Route path="/training/prs">
                    Training PRs
                  </Route>
                </Switch>
              </Typography>
            </Link>
          </div>
          {isLoggedIn ? (
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          ) : (
            <Button color="inherit" onClick={handleLogin}>Login</Button>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        {isLoggedIn ? (
          <Switch>
            <Route path="/tdee">
              <TDEEScreen />
            </Route>
            <Route path="/training/log">
              <TrainingLogScreen />
            </Route>
            <Route path="/training/templates">
              <TrainingTemplatesScreen />
            </Route>
            <Route path="/training/prs">
              <TrainingPRsScreen />
            </Route>
            <Route path="/">
              <Button variant="contained" color="primary" onClick={navigate('/tdee')}>TDEE</Button><br />
              <Button variant="contained" color="primary" onClick={navigate('/training/log')}>Training Log</Button><br />
              <Button variant="contained" color="primary" onClick={navigate('/training/templates')}>Training Templates</Button><br />
              <Button variant="contained" color="primary" onClick={navigate('/training/prs')}>Training PRs</Button><br />
            </Route>
          </Switch>
        ) : (
          <div className={classes.centered}>
            <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
