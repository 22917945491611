import React, { useEffect, useMemo, useState } from 'react';
import {useSelector} from 'react-redux';
import { parseExerciseValue } from '../../helpers';

function TrainingPRsScreen() {
  const db = useSelector(store => store.data.db);
  const training = useSelector(store => store.data.dates?.training);
  const [exercises, setExercises] = useState([]);
  const [PRs, setPRs] = useState();
  const sheet = useMemo(() => {
    if (!db) return;
    return db.sheet('Training - PRs', {});
  }, [db]);

  useEffect(() => {
    if (!sheet) return;
    sheet.getRaw('COLUMNS').then(rows => {
      setExercises(rows[0].filter(i => i).filter(i => !['RM PRs', 'Templates'].includes(i)));
    });
  }, [sheet]);

  useEffect(() => {
    if (!training) return;
    if (!exercises.length) return;

    const PRs = {};

    for (const date in training) {
      const item = training[date].item;
      if (!item?.exercises) continue;

      item.exercises.forEach(exercise => {
        if (!exercises.includes(exercise.name)) return;
        let sets = parseExerciseValue(exercise.value);
        sets = sets.filter(set => set.reps <= 10);

        if (!sets.length) return;
        PRs[exercise.name] = PRs[exercise.name] || {};

        sets.forEach(set => {
          PRs[exercise.name][set.reps] = PRs[exercise.name][set.reps] || {};
          if (!PRs[exercise.name][set.reps].weight || PRs[exercise.name][set.reps].weight <= set.weight) {
            PRs[exercise.name][set.reps].date = date;
            PRs[exercise.name][set.reps].weight = set.weight;
          }
        });
      });
    }

    setPRs(PRs);
  }, [training, exercises]);

  if (!PRs) return null;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rm => (
              <th key={rm}>{rm}RM</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {exercises.map(exercise => (
            <React.Fragment key={exercise}>
              <tr>
                <th scope="row" rowSpan={2}>{exercise}</th>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rm => (
                  <td key={rm}>{PRs[exercise]?.[rm.toString()]?.weight}</td>
                ))}
              </tr>
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(rm => (
                  <td key={rm}>{PRs[exercise]?.[rm.toString()]?.date}</td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TrainingPRsScreen;
